import { render, staticRenderFns } from "./Systemconfig.vue?vue&type=template&id=18b0c30c&scoped=true&"
import script from "./Systemconfig.vue?vue&type=script&lang=js&"
export * from "./Systemconfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18b0c30c",
  null
  
)

export default component.exports